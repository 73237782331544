.modal {
  position: fixed;
  inset: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  white-space: pre-wrap;
  max-width: var(--screen-width-small);
}

.modal-content__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  background-color: var(--color-white);
  padding: 1.5rem 2rem;
  padding-right: var(--header-spacing-right);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  border-bottom: 2px solid var(--color-grey-30);
  font-size: var(--font-size-fluid-medium);
}

.modal-content__header > * {
  display: flex;
}

.modal-content__header .close-button {
  flex-shrink: 0;
}

.modal-content__header .close-button .button__icon {
  height: 16px;
  max-width: 16px;
}

.modal-content__body {
  font-size: var(--font-size-fluid-xxsmall);
  padding: 2rem;
  background-color: var(--color-grey-20);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.modal-content__scroll-container {
  max-height: 300px;
  overflow-y: auto;
}

@media (max-width: 48rem) {
  .modal-content__header,
  .modal-content__body {
    padding: 1.5rem;
  }

  .modal-content__scroll-container {
    max-height: calc(
      100vh - 260px
    ); /* 260px to keep modal centered but not hiding main header */
  }
}
