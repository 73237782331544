.main-layout {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 48px;

  background-color: var(--main-background-color);
  z-index: 0; /* to contain child z-indexes */
  position: relative;
}

.main-layout__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-left: calc(
    100vw - 100%
  ); /* 100vw - 100% compensates for the width of the scrollbar */
  max-width: var(--main-content-max-width);
  min-width: 48rem;
  background-color: inherit;
}

.main-layout__inner-content {
  flex-grow: 1;
  position: relative;
  z-index: 0; /* to contain z-index within the inner content */
}

.main-layout__footer {
  margin-top: auto;
  padding-top: 6rem;
}

@media (max-width: 48rem) {
  .main-layout__content {
    min-width: unset;
    width: 100%;
    margin: 0;
  }

  .main-layout__footer {
    padding-top: 1.75rem;
  }
}
