.label-input {
  position: relative;
}

.label-input__input {
  width: 100%;
  font-size: var(--font-size-xlarge);
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: solid 1px var(--color-grey-40);
  border-radius: 0px;
  padding: 8px 0;
  outline: none;
}

.label-input__input:focus,
.label-input__input:not(:placeholder-shown) {
  border-bottom: solid 1px var(--color-black);
}

.label-input__label {
  position: absolute;
  left: 0;
  right: 0;
  top: 8px;
  pointer-events: none;

  font-family: inherit;
  font-size: var(--font-size-large);
  letter-spacing: -0.01em;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--color-grey-60);

  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms, font-size 200ms;
}

.label-input__input:focus ~ .label-input__label,
.label-input__input:not(:placeholder-shown) ~ .label-input__label {
  font-size: var(--font-size-xsmall);
  color: var(--color-black);
  transform: translateY(-24px);
}
