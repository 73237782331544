.vas-scale-ticks {
  position: relative;
  height: 100%;
  width: 100%;
}

.vas-scale-ticks__tick {
  position: absolute;
  display: inline;
  background-color: var(--color-white);
  height: 100%;
  width: var(--vas-scale-tick-width);
}

.vas-scale-ticks--vertical .vas-scale-ticks__tick {
  width: 100%;
  height: var(--vas-scale-tick-width);
}
