.fetch-error {
  display: flex;
  align-items: center;
  font-size: var(--font-size-xxsmall);
  line-height: 1.2;
}

.fetch-error__icon {
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  margin-right: 0.375rem;
}

[dir="rtl"] .fetch-error__icon {
  margin-right: 0;
  margin-left: 0.375rem;
}
