.public-layout {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 45px 48px;

  background-color: var(--color-white);
  z-index: 0; /* to contain child z-indexes */
  position: relative;
}

.public-layout__content {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  max-width: 1440px;
}

.public-layout__header {
  display: flex;
  align-items: flex-end;
  height: var(--header-height);
}

.public-layout__header-logo {
  height: 1.375rem;
  width: auto;
}

.public-layout__content main {
  flex: 1;
}

@media (max-width: 48rem) {
  .public-layout {
    padding: 0 24px 28px;
  }
}
