.event-list__title {
  font-size: var(--font-size-fluid-xxsmall);
  font-weight: 400;
  margin: 0;
}

.event-list__group-title {
  font-size: var(--font-size-xxsmall);
  font-weight: 400;
  margin: 0 0 1.25rem 0;
  display: flex;
}

.event-list__info {
  font-size: var(--font-size-fluid-xxsmall);
  color: var(--color-grey-70);
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 30rem) {
  .event-list__info {
    height: 6rem;
  }
}
