.event-list-item {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: var(--color-black);
  border-radius: inherit;
  outline: none;
}

.event-list-item__label {
  font-size: var(--font-size-fluid-xxsmall);
  font-weight: 400;
  margin: 0;
}

.event-list-item__data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--font-size-xxsmall);
  margin-left: 0.75rem;
}

[dir="rtl"] .event-list-item__data {
  margin-left: 0;
  margin-right: 0.75rem;
}

.event-list-item__date {
  color: var(--color-grey-80);
}

.event-list-item__info {
  margin: 0;
  color: var(--color-green-darker);
}

.event-list-item--past .event-list-item__info,
.event-list-item--disabled .event-list-item__info {
  color: var(--color-grey-70);
}

.event-list-item__icon {
  height: 1rem;
  width: 1.438rem;
  margin-left: auto;
  flex-shrink: 0;
}

[dir="rtl"] .event-list-item__icon {
  margin-left: 0;
  margin-right: auto;
  transform: rotate(180deg);
}

@media (max-width: 30rem) {
  .event-list-item__icon {
    display: none;
  }
}

.event-list-item--disabled .event-list-item__icon {
  display: none;
}
