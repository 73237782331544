.thank-you-page__main-content {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem 1.25rem 2.875rem;
  min-height: 12.625rem;
}

.thank-you-page__title {
  margin: 0 0 0.1em;
  font-size: var(--font-size-fluid-xxlarge);
  font-weight: 400;
  letter-spacing: -0.225rem;
  line-height: 1.1;
  color: var(--color-purple-dark);
  animation: fadeIn 750ms ease-out forwards;

  /* Gradient text */
  background: linear-gradient(89.99deg, #7b4dff 27.7%, #f4e6e3 69.56%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.thank-you-page__paragraph {
  margin: 0;
  line-height: 1.3;
  color: var(--color-black);
  font-size: var(--font-size-xsmall);
}

.thank-you-page__check-icon {
  margin: auto;
  height: 2.75rem;
}

@media (max-width: 30rem) {
  .thank-you-page {
    padding-top: 3.313rem;
    padding-bottom: 3.5rem;
  }
}
