.toggle-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: inherit;
  font-size: var(--font-size-xsmall);
  line-height: 1.2;
  border-radius: 2.5rem;
  min-height: 2.563rem;
  padding: 0.625rem 1.875rem;

  background-color: transparent;
  color: var(--color-black);
  border: 1px solid var(--color-grey-30);
}

.toggle-button:hover {
  border-color: var(--color-grey-50);
}

.toggle-button--selected {
  background-color: var(--color-orange);
  color: var(--color-white);
  border: 1px solid transparent;
}

.toggle-button--selected:hover {
  border-color: transparent;
}

.toggle-button--disabled {
  pointer-events: none;
}
