.not-found__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
  padding-right: var(--header-spacing-right);
  padding-left: var(--header-spacing-left);
}

.not-found__title {
  font-size: 6em;
  line-height: 1;
  margin-bottom: 1rem;
}

.not-found__info {
  font-size: var(--font-size-xsmall);
  color: var(--color-grey-60);
  margin-bottom: 2rem;
}
