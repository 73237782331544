.status-message.error {
  display: flex;
  align-items: center;
  color: var(--color-red);
}

.status-message.success {
  display: flex;
  align-items: center;
  color: var(--color-green-dark);
}

.status-message svg {
  margin-right: 0.5rem;
}

[dir="rtl"] .status-message svg {
  margin-right: 0;
  margin-left: 0.5rem;
}
