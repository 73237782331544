.video-overlay-join__action {
  width: 100%;
  margin: 0 1.125rem;
}

.video-overlay-join__action .button--large {
  font-size: var(--font-size-fluid-medium);
  width: 100%;
  height: 4rem;
}

.video-overlay-join__action .button--large .button__icon {
  height: 2rem;
  max-width: unset;
}

.video-overlay-join__action .button--large .button__label {
  margin-left: 1rem;
}
