.dashboard {
  border-radius: var(--border-radius);
  display: flex;
  flex-wrap: wrap;
  gap: var(--dashboard-gap-size);
  overflow: hidden;
  isolation: isolate;
}

.dashboard__header {
  display: flex;
  align-items: center;
  background: var(--color-white);
  width: 100%;
  min-height: 2.875rem;
  padding: 1rem;
  font-size: var(--font-size-xxsmall);
}

.dashboard h2 {
  font-size: var(--font-size-fluid-xlarge);
  font-weight: 400;
  line-height: 1;
  margin: 0;
}
