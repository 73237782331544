.list-item {
  background-color: #f4f4f4;
  border-radius: 1.25rem;
  padding: 0.5rem 1.5rem 0.438rem 0.75rem;
  cursor: pointer;
  min-height: 4.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-item:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.list-item:hover {
  background-color: #efefef;
}

.list-item:focus-within {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.list-item--disabled {
  pointer-events: none;
}

@media (max-width: 30rem) {
  .list-item {
    padding: 0.5rem 0.75rem;
  }
}
