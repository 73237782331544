.file-select {
  display: flex;
  width: 100%;
}

.file-select__input {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.file-select__label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: var(--font-size-xsmall);
  border-radius: 999px; /* gives a border radius that is half the height of the button */
  height: var(--button-size-large);
  padding-left: 0.938rem;
  padding-right: 0.938rem;
  min-width: 6.938rem;
  background-color: var(--color-blue);
  color: var(--color-white);
  gap: 0.438rem;
}

.file-select__label:hover {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.file-select__label svg {
  height: 1.25rem;
  width: 1.25rem;
  transition: transform 200ms ease-in-out;
}

.file-select--disabled .file-select__label {
  background-color: var(--color-grey-10);
  color: var(--color-grey-40);
  pointer-events: none;
}

@media (max-width: 30rem) {
  .file-select {
    flex-direction: column;
    align-items: stretch;
  }

  .file-select__label {
    height: var(--button-size-large-mobile);
  }
}
