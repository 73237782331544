@font-face {
  font-family: "Monument Grotesk";
  src: url("./content/fonts/MonumentGrotesk-Regular.woff2") format("woff2"),
    url("./content/fonts/MonumentGrotesk-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Viedoc Monument Grotesk";
  src: url("./content/fonts/ViedocMonumentGrotesk-Regular.woff2")
      format("woff2"),
    url("./content/fonts/ViedocMonumentGrotesk-Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Monument Grotesk";
  src: url("./content/fonts/MonumentGrotesk-Bold.woff2") format("woff2"),
    url("./content/fonts/MonumentGrotesk-Bold.woff") format("woff");
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: "Monument Grotesk";
  src: url("./content/fonts/MonumentGrotesk-RegularItalic.woff2")
      format("woff2"),
    url("./content/fonts/MonumentGrotesk-RegularItalic.woff") format("woff");
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Monument Grotesk";
  src: url("./content/fonts/MonumentGrotesk-BoldItalic.woff2") format("woff2"),
    url("./content/fonts/MonumentGrotesk-BoldItalic.woff") format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}

@font-face{
  font-family: "MPlus Rounded";
  src: url("./content/fonts/MPLUSRounded1c-Regular.ttf");
  font-display: swap;
}