.file-details {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 0;
  gap: 10px;
}

.file-details--processing * {
  cursor: progress !important;
}

.file-details__thumbnail {
  max-height: 3rem;
  max-width: 6rem;
  border-radius: 10px;
  color: var(--color-blue);
}

.file-details--link img,
.file-details--link dt {
  cursor: pointer;
}

.file-details__meta {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  min-width: 0;
}

.file-details__meta dd {
  font-size: var(--font-size-xxsmall);
  margin: 0;
  line-height: 1.3;
}

.file-details__meta dt {
  color: var(--color-black);
  font-size: var(--font-size-small);
  line-height: 1.3;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
