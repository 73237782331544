.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: var(--font-size-xxsmall);
  border-radius: 999px; /* gives a border radius that is half the height of the button */

  background-color: var(--color-white);
  color: var(--color-black);
}

.button:hover {
  background-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.button__icon {
  height: 1.25rem;
  max-width: 1.5rem;
}

.button__icon svg {
  height: 100%;
  width: 100%;
}

.button__icon + .button__label {
  margin-left: 0.313rem;
}

[dir="rtl"] .button__icon + .button__label {
  margin-right: 0.313rem;
}

.button:disabled {
  background-color: var(--color-grey-40);
  color: var(--color-grey-20);
  pointer-events: none;
}

/* -- BUTTON SIZES -- */

.button--large {
  font-size: var(--font-size-xsmall);
  height: var(--button-size-large);
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  min-width: 6.938rem;
}

.button--large.button--with-icon {
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.button--large .button__icon + .button__label {
  margin-left: 0.438rem;
}

[dir="rtl"] .button--large .button__icon + .button__label {
  margin-right: 0.438rem;
}

@media (max-width: 48rem) {
  .button--large {
    width: 100%;
    height: var(--button-size-large-mobile);
  }
}

.button--small {
  height: var(--button-size-small);
  min-width: 4rem;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.button--small.button--with-icon {
  padding-left: 0.813rem;
  padding-right: 0.813rem;
}

/* -- BUTTON TYPES -- */

.button--circular {
  width: var(--ciruclar-button-size-large);
  height: var(--ciruclar-button-size-large);
  padding-left: unset;
  padding-right: unset;
  min-width: unset;
}

.button--small.button--circular {
  width: var(--circular-button-size-small);
  height: var(--circular-button-size-small);
}

.button--circular.button--with-icon {
  padding: 0;
}

.button--small.button--circular .button__icon {
  height: 1.125rem;
  max-width: 1.125rem;
}

.button--rectangle {
  width: var(--rectangular-button-size-large);
  height: calc(var(--rectangular-button-size-large) / 2);
  padding-left: unset;
  padding-right: unset;
  min-width: unset;
  border-radius: 0.5rem;
}

.button--small.button--rectangle {
  width: var(--rectangular-button-size-small);
  height: calc(var(--rectangular-button-size-small) / 2);
}

.button--small.button--rectangle .button__icon {
  height: 2rem;
  max-width: 4.5rem;
}

/* -- BUTTON COLORS -- */

.button--blue {
  background-color: var(--color-blue);
  color: var(--color-white);
}

.button--gray {
  background-color: var(--color-grey-70);
  color: var(--color-white);
}

.button--light-gray {
  background-color: var(--color-grey-20);
  color: var(--color-black);
}

.button--green {
  background-color: var(--color-green-dark);
  color: var(--color-white);
}

.button--orange {
  background-color: var(--color-orange-dark);
  color: var(--color-white);
}

.button--black {
  background-color: var(--color-black);
  color: var(--color-white);
}

.button--black:hover {
  background-color: var(--color-grey-70);
  background-image: none;
}

.button--transparent {
  background-color: transparent;
}

.button--purple-dark {
  background-color: var(--color-purple-dark);
  color: var(--color-white);
}
