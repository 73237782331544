.footer {
  align-items: center;
  color: var(--color-grey-70);
  display: flex;
  flex-direction: column;
  font-size: 0.813rem;
  margin-top: auto;
  text-align: center;
}

.footer--left-align {
  align-items: flex-start;
  text-align: left;
}

.footer__error {
  color: var(--color-red);
}

.footer__logo {
  height: 2.5rem;
  width: auto;
}

.footer--dark {
  color: var(--color-grey-50);
}
