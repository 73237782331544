:root {
  --font-size-xxsmall: 1rem; /* 16px */
  --font-size-xsmall: 1.125rem; /* 18px */
  --font-size-small: 1.313rem; /* 21px */
  --font-size-medium: 1.563rem; /* 25px */
  --font-size-large: 1.75rem; /* 28px */
  --font-size-xlarge: 2rem; /* 32px */
  --font-size-xxlarge: 2.5rem; /* 40px */
  --font-size-xxxlarge: 4rem; /* 64px */
}

/* Fluidly scale typography across viewport widths 384px to 1440px */

/* Fallback values if clamp is not supported */
:root {
  --font-size-fluid-xxsmall: var(--font-size-xxsmall);
  --font-size-fluid-xsmall: var(--font-size-xsmall);
  --font-size-fluid-small: var(--font-size-small);
  --font-size-fluid-medium: var(--font-size-medium);
  --font-size-fluid-large: var(--font-size-large);
  --font-size-fluid-xlarge: var(--font-size-xlarge);
}

/* Add fluid values if clamp is supported */
/* Calculated using: https://websemantics.uk/tools/responsive-font-calculator/ */

@supports (
  font-size: clamp(0.875rem, calc(0.875rem + ((1vw - 0.24rem) * 0.1894)), 1rem)
) {
  :root {
    --font-size-fluid-xxsmall: clamp(
      1rem,
      calc(1rem + ((1vw - 0.24rem) * 0.1894)),
      1.125rem
    ); /* 16px to 18px */
    --font-size-fluid-xsmall: clamp(
      1.125rem,
      calc(1.125rem + ((1vw - 0.24rem) * 0.2848)),
      1.313rem
    ); /* 18px to 21px */
    --font-size-fluid-small: clamp(
      1.313rem,
      calc(1.313rem + ((1vw - 0.24rem) * 0.3788)),
      1.563rem
    ); /* 21px to 25px */
    --font-size-fluid-medium: clamp(
      1.563rem,
      calc(1.563rem + ((1vw - 0.24rem) * 0.2833)),
      1.75rem
    ); /* 25px to 28px */
    --font-size-fluid-large: clamp(
      1.75rem,
      calc(1.75rem + ((1vw - 0.24rem) * 0.3788)),
      2rem
    ); /* 28px to 32px */
    --font-size-fluid-xlarge: clamp(
      2rem,
      calc(2rem + ((1vw - 0.24rem) * 0.3788)),
      2.25rem
    ); /* 32px to 36px */
    --font-size-fluid-xxlarge: clamp(
      4rem,
      calc(4rem + ((1vw - 0.24rem) * 2.4621)),
      5.625rem
    ); /*  64px to 90px */
  }
}
