.textarea-wrapper {
  background-color: var(--color-white);
  border-radius: 0.625rem;
  border: 1px solid #d1d1d1;
  width: 100%;
  overflow: hidden;
  display: flex;
  max-width: 100%;
}

.textarea-wrapper--invalid {
  border: 1px solid var(--color-red);
}

.textarea-wrapper:focus-within {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 1px;
}

.textarea {
  background-color: inherit;
  border-radius: inherit;
  border: none;
  font-family: inherit;
  font-size: var(--font-size-xsmall);
  letter-spacing: 0.02em;
  width: 100%;
  outline: none;
  padding: 0.7rem 1rem;
  resize: vertical;
  margin: 0;
}

.textarea:disabled {
  background: var(--color-grey-10);
  color: var(--color-grey-70);
  -webkit-text-fill-color: var(--color-grey-70); /* Safari */
  opacity: 1; /* iOS */
}
