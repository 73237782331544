.form-navigation {
  display: flex;
  flex-direction: row;
}

.form-navigation__button--next {
  margin-left: auto;
}

.form-navigation__button--submit {
  margin-left: auto;
  width: auto !important;
}

[dir="rtl"] .form-navigation__button--next {
  margin-right: auto;
  margin-left: 0;
}

[dir="rtl"] .form-navigation__button--next .button__icon {
  transform: rotate(180deg);
}

.form-navigation__button--previous .button__icon {
  transform: rotate(180deg);
}

[dir="rtl"] .form-navigation__button--previous .button__icon {
  transform: none;
}

.form-navigation__button--center {
  margin: 0 auto;
}
