.form__button-container {
  padding: 1.25rem 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 30rem) {
  .form__button-container {
    flex-direction: column;
  }
}
