.validation-error {
  color: var(--color-red);
  font-size: var(--font-size-xxsmall);
  margin-top: 1rem;
  min-height: 1.25rem;
}

.validation-error:empty {
  padding: 0;
}
