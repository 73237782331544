.unanswered-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: var(--color-black);
  outline: none;
}

.unanswered-question svg {
  flex-shrink: 0;
  height: 1rem;
  width: 1.438rem;
}

.unanswered-question__header {
  font-size: var(--font-size-xxsmall);
  color: var(--color-red);
}

.unanswered-question__label {
  font-size: var(--font-size-fluid-small);
  font-weight: 400;
  margin: 0 1rem 0 0;
}

[dir="rtl"] .unanswered-question svg {
  transform: rotate(180deg);
}
