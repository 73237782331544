.dashboard-tile {
  width: calc(50% - var(--dashboard-gap-size) / 2);
  flex: 1 1 auto;
  display: flex;
  background-color: white;
  color: var(--color-black);
  flex-direction: row;
  justify-content: space-between;
  height: 11.813rem;
  padding: 1.25rem 1.25rem 1.25rem 1.5rem;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
  border: none;
  outline-offset: -0.8rem;
}

.dashboard-tile:-moz-focusring {
  outline: 2px dotted currentColor;
}

[dir="rtl"] .dashboard-tile {
  text-align: right;
}

.dashboard-tile__content {
  width: 100%;
}

.dashboard-tile__controls {
  flex-shrink: 0;
  margin-left: 8px;
}

.dashboard-tile:not(.dashboard-tile--disabled):hover {
  opacity: 0.9;
}

.dashboard-tile--disabled {
  color: var(--color-grey-60);
  cursor: default;
  pointer-events: none;
}

.dashboard-tile--error {
  opacity: 0.5;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 48rem) {
  .dashboard-tile {
    width: 100%;
  }
}
