.file-upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.file-upload .validation-error {
  padding: 1rem 0.75rem 0;
}

.file-upload .file-details__thumbnail,
.file-upload .file-details__meta {
  height: 3rem;
}

.file-upload__button--trash {
  display: flex;
  align-self: center;
  flex-shrink: 0;
  background-color: #dfdfdf;
  color: var(--color-grey-70);
}
