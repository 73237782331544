.circular-progress {
  width: 1.875rem;
  height: 1.875rem;
}

.circular-progress--large {
  width: 3rem;
  height: 3rem;
}

.circular-progress__background {
  stroke: var(--color-grey-30);
}

.circular-progress__value {
  transform: rotate(-90deg);
  transform-origin: center;
  transition: stroke-dashoffset 600ms ease-in-out;
  stroke: var(--color-black);
}

.circular-progress--success .circular-progress__value {
  stroke: var(--color-green-dark);
}

/* progress colors */

.circular-progress__value--black {
  stroke: var(--color-black);
}

.circular-progress__value--blue {
  stroke: var(--color-blue);
}

.circular-progress__value--green {
  stroke: var(--color-green-dark);
}
