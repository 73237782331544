.file-preview {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin: 1rem 0 0;
  padding-top: 1rem;
  border-top: solid 2px var(--color-grey-20);
}

.file-preview-content {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  color: var(--color-grey-80);
  background-color: var(--color-grey-10);
  border-radius: 1.25rem;
  min-height: 4.5rem;
  font-size: var(--font-size-small);
}

.file-preview--pending .file-preview-content {
  padding-left: 1.3rem;
}

[dir="rtl"] .file-preview--pending .file-preview-content {
  padding-left: 0.75rem;
  padding-right: 1.3rem;
}
