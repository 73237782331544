.input {
  padding: 0.7rem 1rem;
  border-radius: 0.625rem;
  border: 1px solid #d1d1d1;
  background-color: var(--color-white);
  width: 20.438rem;
  max-width: 100%;
  height: 2.563rem;
  font-family: inherit;
  font-size: var(--font-size-xsmall);
  color: var(--color-black);
}

.input:hover:not(.input--invalid) {
  border-color: var(--color-grey-50);
}

.input::placeholder {
  color: var(--color-grey-70);
}

.input:hover:not(.input--invalid)::placeholder {
  color: var(--color-grey-80);
}

.input:disabled {
  background: var(--color-grey-10);
  color: var(--color-grey-70);
  -webkit-text-fill-color: var(--color-grey-70); /* Safari */
  opacity: 1; /* iOS */
  pointer-events: none;
}

.input--invalid {
  border: 1px solid var(--color-red);
}

.input--full-width {
  width: 100%;
}

@media (max-width: 30rem) {
  .input {
    width: 100%;
  }
}
