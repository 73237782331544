.box__header {
  display: flex;
  align-items: center;
  min-height: 3.75rem;
  width: 100%;
  padding: 0.625rem 1.563rem;
  background: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: var(--color-white);
  border-bottom: 2px solid var(--main-background-color);
}

.box__header--sticky {
  position: sticky;
  top: var(--header-height);
  z-index: 2;
  box-shadow: 0 -10px 0 10px var(--main-background-color);
}
