.box__content {
  display: flex;
  border-radius: var(--border-radius);
  flex-direction: column;
}

.box__footer {
  margin-top: 1rem;
  padding-right: var(--header-spacing-right);
  padding-left: var(--header-spacing-left);
}

.box__footer--hide {
  opacity: 0 !important;
}

.box__footer-appear {
  opacity: 0;
}

.box__footer-appear-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.box__footer-enter {
  opacity: 0;
}

.box__footer-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-out;
}

.box__footer-exit {
  opacity: 1;
}

.box__footer-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.box__content > *:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.box__content > *:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
