.pdf {
  background-color: var(--main-background-color);
  min-height: 100vh;
}

.pdf__page {
  max-width: var(--main-content-max-width);
  padding: 10px;
  margin: auto;
  page-break-before: always;
}

.pdf__header {
  display: none;
}

.pdf__footer {
  display: none;
}
