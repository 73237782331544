.arrow-badge {
  height: var(--circular-button-size-small);
  width: var(--circular-button-size-small);
  background-color: var(--color-grey-70);
  color: var(--color-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-badge--white {
  background-color: var(--color-white);
  color: var(--color-black);
}

.arrow-badge--green {
  background-color: var(--color-white);
  color: var(--color-green-dark);
}

.arrow-badge__icon {
  height: 1.125rem;
  max-width: 1.125rem;
}

[dir="rtl"] .arrow-badge__icon {
  transform: rotate(180deg);
}
