.animated-check path {
  stroke-dasharray: 75;
  stroke-dashoffset: 75;
  animation: drawCheck 500ms forwards cubic-bezier(0.43, 0.66, 0.11, -0.16),
    fadeOut 300ms 1000ms ease-out forwards;
}

@keyframes drawCheck {
  to {
    stroke-dashoffset: 0;
  }
}
