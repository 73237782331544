.vas-scale-thumb {
  position: absolute;
  top: 0;
  width: 30px;
  height: 100%;
  transform: translateX(-50%);
}

.vas-scale-thumb--vertical {
  height: 30px;
  width: 100%;
  transform: translateY(-50%);
}

.vas-scale-thumb__icon {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.vas-scale-thumb--vertical .vas-scale-thumb__icon {
  transform: translateY(-50%) rotate(-90deg);
  top: 50%;
  left: -3px;
}

[dir="rtl"] .vas-scale-thumb--vertical .vas-scale-thumb__icon {
  transform: translateY(-50%) rotate(90deg);
  right: -3px;
}
