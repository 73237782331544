.study-info {
  padding-right: var(--header-spacing-right);
  padding-left: var(--header-spacing-left);
}

.study-info__title {
  font-size: var(--font-size-xlarge);
  line-height: 1.1;
  font-weight: 400;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  max-width: 20rem;
}

.study-info__section {
  min-height: 11.25rem;
}

.study-info__description {
  color: var(--color-grey-70);
  font-size: var(--font-size-xxsmall);
}

.study-info__section-title {
  font-size: var(--font-size-medium);
  font-weight: 400;
  margin: 0;
}

@media (max-width: 48rem) {
  .study-info {
    padding: 0;
  }

  .study-info__title {
    padding-right: var(--header-spacing-right);
    padding-left: var(--header-spacing-left);
  }
}
