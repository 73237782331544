.select {
  display: inline-flex;
  position: relative;
  height: 2.688rem;
  background-color: var(--color-grey-10);
  border-radius: 2.5rem;
  border: 1px solid transparent;
  max-width: 100%;
}

.select:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    var(--color-white);
}

.select--selected {
  background: var(--color-orange);
  color: var(--color-white);
}

.select--selected:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    var(--color-orange);
}

.select--invalid {
  border: 1px solid var(--color-red);
}

.select--disabled {
  pointer-events: none;
}

.select:focus-within {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.select__native-select {
  /* resets */
  appearance: none;
  background-color: transparent;
  color: inherit !important;
  border: none;
  border-radius: inherit;
  outline: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  cursor: inherit;

  padding-top: 0.625rem;
  padding-right: 3.875rem;
  padding-bottom: 0.625rem;
  padding-left: 1rem;
  font-size: var(--font-size-xsmall);
  line-height: 1;
  text-overflow: ellipsis;
}

.select__native-select:disabled {
  opacity: 1;
}

.select__option {
  color: initial;
}

.select__icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 0.938rem;
  transform: translateY(-50%) rotate(90deg);
  pointer-events: none;
}

[dir="rtl"] .select__native-select {
  padding-left: 3.875rem;
  padding-right: 1rem;
}

[dir="rtl"] .select__icon {
  left: 1rem;
  right: unset;
}

@media (max-width: 30rem) {
  .select {
    width: 100%;
  }
}
