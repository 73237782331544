.start-unscheduled {
  color: var(--color-white);
  background-color: transparent;
  position: relative;
  overflow: hidden;
}

.start-unscheduled__title {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.start-unscheduled__background {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  min-width: 100%;
  max-height: 100%;
  z-index: -1;
}

.start-unscheduled__arrow {
  position: relative;
  z-index: 1;
}
