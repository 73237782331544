.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;
}

.loading-overlay__indicator {
  height: 44px;
  width: 44px;
}

.loading-overlay--show {
  opacity: 1;
  pointer-events: initial;
}
