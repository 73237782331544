.number-badge {
  background-color: var(--color-orange-dark);
  border-radius: 50%;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.375rem;
  width: 2.375rem;
  font-size: var(--font-size-small);
}
