.change-pin-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.change-pin-form__row {
  margin-top: 3rem;
}

.change-pin-form__row:first-child {
  margin-top: 0;
}

.change-pin-form__details {
  margin-top: 1rem;
}

.change-pin-form__buttons {
  display: flex;
  gap: 0.625rem;
}

.change-pin-form__input {
  width: 480px;
  max-width: 100%;
}

@media (max-width: 48rem) {
  .change-pin-form {
    align-items: stretch;
  }

  .change-pin-form__buttons {
    flex-direction: column;
    width: 100%;
  }

  .change-pin-form__input {
    width: 100%;
  }
}
