.settings form {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-xsmall);
}

.settings .box__section:not(:first-child) {
  padding-bottom: 4rem;
}

.settings__title {
  font-family: inherit;
  font-size: var(--font-size-xsmall);
  font-weight: normal;
  margin: 0;
}

.settings__label {
  display: block;
  margin-bottom: 0.625rem;
}

.settings__form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  margin-top: 1rem;
}

.settings__error {
  position: absolute;
  margin-top: 0.5rem;
  color: var(--color-red);
}

.settings__button-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.settings .checkbox__wrapper {
  margin-top: 0.5rem;
}

.settings .checkbox__wrapper:first-child {
  margin-top: 1rem;
}

.settings__current-pin {
  display: flex;
  align-items: center;
}

.settings__highlighted-value {
  font-size: var(--font-size-large);
  line-height: 1;
  color: var(--color-purple-dark);
}
