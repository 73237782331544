.video-overlay {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 48px;
}

.video-overlay__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 58.75rem;
  min-width: 48rem;
}

.video-overlay__header {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding-right: var(--header-spacing-right);
  padding-left: var(--header-spacing-left);
  background-color: inherit;
}

.video-overlay__header-logo {
  height: 1.5rem;
  width: auto;
}

.video-overlay__content main {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding-left: var(--header-spacing-left);
  padding-right: var(--header-spacing-right);
}

.overlay--minimized .video-overlay {
  padding: 0;
}

.overlay--minimized .video-overlay__content {
  min-width: unset;
  max-width: unset;
  width: var(--screen-width-small);
}

.overlay--minimized .video-overlay__content main {
  padding: 1.125rem 1.25rem 1.125rem 1.625rem;
}

@media (max-width: 48rem) {
  .video-overlay__content {
    min-width: unset;
    width: 100%;
    margin: 0;
  }

  .video-overlay__content main {
    padding: 0;
  }
}
