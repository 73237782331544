.next-event-details__form-name {
  font-size: var(--font-size-small);
  line-height: 1.25;
  font-weight: 400;
  margin: 0;
  margin-bottom: 0.125rem;
}

.next-event-details__info {
  margin: 0;
  margin-bottom: 0.125rem;
  font-size: var(--font-size-xxsmall);
  line-height: 1.25;
}
