.next-event-section {
  margin: 0 auto;
  text-align: center;
  padding: 0.375rem 1.25rem;
}

.next-event-section__paragraph {
  margin: 0;
  line-height: 1.3;
  color: var(--color-black);
  font-size: var(--font-size-xsmall);
}

.next-event-section__info {
  margin: 0;
  color: var(--color-grey-70);
  font-size: var(--font-size-xxsmall);
}

.next-event-section__date-icon {
  margin: 0.25rem 0 0.75rem 0;
}
