.video-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-white);
}

.video-action--large {
  gap: 0.25rem;
}

.video-action--large .button:only-child {
  height: 100%;
}
