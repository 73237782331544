.toggle-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.toggle-group--vertical {
  flex-direction: column;
  align-items: stretch;
}

.toggle-group--invalid > [role="radio"],
.toggle-group--invalid > [role="checkbox"] {
  border-color: var(--color-red);
}
