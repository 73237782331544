.overlay {
  position: fixed;
  inset: 0px;
  opacity: 0;
  transition: opacity 400ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}

.overlay__content {
  position: absolute;
  inset: 0px;
  background: var(--color-black);
  overflow: auto;
}

.overlay--minimized {
  top: unset;
  justify-content: center;
}

.overlay--minimized .overlay__content {
  position: static;
  max-width: var(--screen-width-small);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  margin-left: calc(
    100vw - 100%
  ); /* 100vw - 100% compensates for the width of the scrollbar */
}

.display-overlay .overlay {
  opacity: 1;
  pointer-events: initial;
  display: flex;
}

@media (max-width: 48rem) {
  .overlay--minimized .overlay__content {
    max-width: unset;
  }
}
