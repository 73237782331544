.html-text {
  font-size: var(--font-size-medium);
}

.html-text span[style*="color: darkgreen"] {
  color: var(--color-green-dark) !important;
}

.html-text span[style*="color: red"] {
  color: var(--color-red) !important;
}

.html-text span[style*="color: darkgray"] {
  color: var(--color-grey-60) !important;
}

.html-text span[style*="font-size: 2em"] {
  font-size: var(--font-size-xlarge) !important;
}

.html-text span[style*="font-size: 1.5em"] {
  font-size: var(--font-size-large) !important;
}

.html-text span[style*="font-size: 0.8em"] {
  font-size: var(--font-size-fluid-xxsmall) !important;
}
