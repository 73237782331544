@import "./font.css";
@import "./typography.css";

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --color-white: #ffffff;
  --color-grey-10: #f2f2f2;
  --color-grey-20: #e7e7e7;
  --color-grey-30: #cccccc;
  --color-grey-40: #b4b4b4;
  --color-grey-50: #999999;
  --color-grey-60: #818181;
  --color-grey-70: #666666;
  --color-grey-80: #4d4d4d;
  --color-grey-90: #333333;
  --color-grey-100: #1a1a1a;
  --color-black: #000000;

  --color-green-darker: #007922;
  --color-green-dark: #008625;
  --color-green: #00ac26;
  --color-yellow: #ebe73d;
  --color-red-dark: #6a0023;
  --color-red: #ff2e00;
  --color-orange-dark: #e0420d;
  --color-orange: #f86800;
  --color-orange-light: #fdf6f3;
  --color-purple-dark: #7b4dff;
  --color-purple: #6f77ff;
  --color-blue-light: #3798e8;
  --color-blue: #0086d2;

  --screen-width-small: 375px;
  --screen-width-medium: 720px;
  --screen-width-large: 1440px;

  --button-size-small: 2.25rem;
  --button-size-large: 2.688rem;
  --button-size-large-mobile: 3rem;
  --circular-button-size-small: 2.375rem;
  --ciruclar-button-size-large: 3rem;
  --rectangular-button-size-small: 7.125rem;
  --rectangular-button-size-large: 9rem;

  --header-spacing-left: 1.5rem;
  --header-spacing-right: 1.5rem;
  --header-height: 3.75rem;

  --main-background-color: #ececec;
  --main-content-max-width: 58.75rem;

  --loading-indicator-animation-speed: 3s;

  --dashboard-gap-size: 2px;

  --border-radius: 1.875rem;
}

body {
  margin: 0;
  font-family: "Viedoc Monument Grotesk", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body:lang(ja) {
  font-family: "MPlus Rounded";
}

body:lang(el) {
  font-family: Arial, Helvetica, sans-serif;
}

body.overlay-visible,
body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  display: block;
  fill: currentColor;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
