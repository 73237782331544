.grid {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: baseline;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr max-content 1.15fr;
  grid-template-areas:
    ". ."
    "title form"
    ". footer";
  position: relative;
}

.grid:before,
.grid:after {
  content: "";
  position: absolute;
  z-index: 1;
  height: 6px;
  left: -3px;
  right: -3px;
}

.grid:before {
  background: linear-gradient(
    to right,
    transparent 6px calc(50% - 3px),
    var(--color-black) calc(50% - 3px) calc(50% + 3px),
    transparent calc(50% + 3px) calc(100% - 6px),
    var(--color-black) calc(100% - 6px)
  );
  top: -3px;
}

.grid:after {
  background: linear-gradient(
    to right,
    var(--color-black) 6px,
    transparent 6px calc(100% - 6px),
    var(--color-black) calc(100% - 6px)
  );
  bottom: -3px;
}

.grid__title {
  grid-area: title;
  font-weight: normal;
  font-size: var(--font-size-xxxlarge);
  line-height: 1.1;
  margin: 0;

  color: var(--color-black);
  padding-right: 70px;
}

.grid__footer {
  grid-area: footer;
}

.grid__form {
  grid-area: form;
  margin-bottom: 70px;
}

@media (max-width: 48rem) {
  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, min-content) 1fr;
    grid-template-areas:
      "title"
      "form"
      "footer";
  }

  .grid__title {
    font-size: var(--font-size-xlarge);
    margin-bottom: 3.875rem;
    margin-top: 2.5rem;
  }

  .grid__footer {
    align-items: center !important;
  }

  .grid:before,
  .grid:after {
    display: none;
  }
}
