.form-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.form-header__progress-indicator {
  margin-left: auto;
  margin-right: 6px;
  color: var(--color-grey-70);
  font-size: var(--font-size-xsmall);
  letter-spacing: 0.1em;
}

[dir="rtl"] .form-header__progress-indicator {
  margin-left: 6px;
  margin-right: auto;
}

.form-header__current-page-indicator {
  color: var(--color-black);
}

.form-header__validation-error {
  display: flex;
  align-items: center;
  color: var(--color-red);
  margin-right: 0.5rem;
}

.form-header__validation-error-text {
  font-size: var(--font-size-fluid-xxsmall);
  line-height: 1.1;
  padding-left: 0.5rem;
}

.form-header__warning-icon {
  flex-shrink: 0;
}

.form-header__title {
  font-size: var(--font-size-fluid-xxsmall);
  font-weight: 400;
  margin: 0 0.5rem 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.form-header__circular-progress {
  flex-shrink: 0;
}
