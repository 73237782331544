.drawing-pad-item {
  width: 100%;
}

.drawing-pad-item__footer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawing-pad-item__info {
  color: var(--color-grey-70);
  font-size: var(--font-size-fluid-xxsmall);
}
