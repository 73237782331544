.numeric-scale {
  position: relative;
  height: 15px;
  margin-bottom: 1rem;
}

.numeric-scale--vertical {
  height: 100%;
  width: 15px;
  margin-right: 1.5rem;
}

[dir="rtl"] .numeric-scale--vertical {
  margin-right: 0;
  margin-left: 1.5rem;
}

.numeric-scale__tick {
  position: absolute;
  background-color: var(--color-grey-40);
  width: var(--vas-scale-tick-width);
  height: 5px;
}

.numeric-scale--vertical .numeric-scale__tick {
  width: 5px;
  height: var(--vas-scale-tick-width);
}

.numeric-scale__tick--long {
  height: 10px;
}

.numeric-scale--vertical .numeric-scale__tick--long {
  width: 10px;
}

.numeric-scale__tick--long:not(:first-child) {
  counter-increment: section 10;
}

.numeric-scale__tick--long:after {
  font-size: var(--font-size-fluid-xxsmall);
  line-height: 1;
  content: counter(section);
  position: absolute;
  display: block;
  top: 17px;
  left: 50%;
  transform: translateX(-50%);
}

.numeric-scale--vertical .numeric-scale__tick--long:after {
  top: 0;
  left: 17px;
  transform: translateY(-50%);
}

[dir="rtl"] .numeric-scale--vertical .numeric-scale__tick--long:after {
  left: unset;
  right: 17px;
}

@media (max-width: 30rem) {
  .numeric-scale__tick:not(.numeric-scale__tick--long) {
    display: none;
  }
}
