.error-boundary {
  padding-left: var(--header-spacing-left);
  padding-right: var(--header-spacing-right);
  margin: auto;
}

.error-boundary pre {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.error-boundary pre code {
  font-family: inherit;
}
