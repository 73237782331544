.welcome-page{
    grid-template-areas:
        "content"
        "footer";
        grid-template-columns: none;
        grid-template-rows: none;

    align-items: center;
    text-align: center;
    justify-self: center;
    height: 100%;
    width: 100%;
    display: grid;
}

.button-continue {
    display: inline;
    font-size: medium;
}

.pt-20{
    padding-top:20px;
}

.welcome-page .login__animated-title {
    animation: welcomeSlideUp 1s forwards;
    transform: translateY(40px);
    opacity: 0;
}

.welcome-page h1 {
    font-family: var(--font-family-viedoc);
    font-size: var(--font-size-xxxlarge);
    line-height: 1.1;
    font-weight: 400;
}

.login__user-name {
    color: #7b4cff;
}

@keyframes welcomeSlideUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}