.unscheduled-event-list-item {
  display: flex;
  align-items: center;
  color: var(--color-black);
  text-decoration: none;
  padding-left: 0.25rem;
  outline: none;
}

.unscheduled-event-list-item__label {
  font-size: var(--font-size-fluid-small);
  margin: 0 1.5rem 0 0;
  font-weight: 400;
}

[dir="rtl"] .unscheduled-event-list-item__label {
  margin: 0 0 0 1.5rem;
}

.unscheduled-event-list-item__icon {
  height: 1rem;
  width: 1.438rem;
  flex-shrink: 0;
  margin-left: auto;
}

[dir="rtl"] .unscheduled-event-list-item__icon {
  margin-left: 0;
  margin-right: auto;
  transform: rotate(180deg);
}
