.video-call-tile {
  color: var(--color-grey-60);
}

.video-call-tile--pending-call {
  color: var(--color-green);
}

.video-call-tile small {
  font-size: var(--font-size-xxsmall);
  line-height: 0.8;
  color: var(--color-black);
  margin-bottom: 0.375rem;
  display: block;
}

.video-call-tile--pending-call small {
  color: var(--color-green-dark);
}

.video-call-tile--pending-call h2 {
  color: var(--color-black);
}

.video-call-tile__badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  background: var(--color-grey-20);
  height: 2.375rem;
  min-width: 2.375rem;
  border-radius: 999px;
  font-size: var(--font-size-xxsmall);
}

.video-call-tile__badge--active {
  height: var(--button-size-small);
  background: var(--color-green-dark);
  color: var(--color-white);
  padding: 0 0.75rem;
}

.video-call-tile__badge--disabled {
  height: var(--button-size-small);
  color: var(--color-grey-60);
  padding: 0 0.75rem;
}

.video-call-tile__badge--disabled .video-call-tile__camera-icon path {
  fill: var(--color-grey-60);
}

[dir="rtl"] .video-call-tile__camera-icon {
  transform: scaleX(-1); /* flips icon horizontally */
}
