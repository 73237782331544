.form-submit__submit-info {
  font-size: var(--font-size-fluid-small);
  padding: 1.25rem 1rem;
  color: var(--color-grey-70);
}

.form-submit__title {
  font-size: 3.438rem;
  color: var(--color-black);
  font-weight: 400;
  line-height: 1.2;
  margin: 0 0 0.5rem;
}

@media (max-width: 30rem) {
  .form-submit__submit-info {
    padding: 0;
  }
}
