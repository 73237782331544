.form-item-group__label {
  background-color: #f7f7f7;
  border-bottom: 2px solid var(--color-grey-20);
  color: var(--color-black);
  padding: 1.125rem 1.563rem 1rem;
}

.form-item-group__label h3 {
  font-size: var(--font-size-medium);
  font-weight: normal;
  margin: 0;
}
