.home {
  padding-left: var(--header-spacing-left);
  padding-right: var(--header-spacing-right);
  margin: auto;
}

.home__header {
  margin-bottom: 1rem;
}

.home__title {
  font-weight: 400;
  line-height: 2.3rem;
  margin-top: 1rem;
  margin-bottom: 0.625rem;
}

.home__help-button {
  display: none;
  margin-top: 1.625rem;
}

.home__status {
  min-height: 1.5rem;
}

@media (max-width: 48rem) {
  .home__title {
    margin-top: 6rem;
  }

  .home__dashboard {
    margin-left: calc(-1 * var(--header-spacing-left));
    margin-right: calc(-1 * var(--header-spacing-right));
  }

  .home__help-button {
    display: block;
  }
}
