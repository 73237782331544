.checkbox__wrapper {
  display: flex;
  position: relative;
  align-items: center;
}

.checkbox__wrapper .checkbox {
  position: absolute;
  opacity: 0;
  margin: 0;
  width: 1.563rem;
  height: 1.563rem;
  cursor: pointer;
}

.checkbox__wrapper svg {
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

[dir="rtl"] .checkbox__wrapper svg {
  margin-right: 0;
  margin-left: 0.5rem;
}

.checkbox__wrapper .checkbox--disabled + svg {
  opacity: 0.5;
}

.checkbox__wrapper svg > * {
  transition: all 0.1s linear;
}

.checkbox__wrapper svg .checkbox__bg {
  fill: var(--color-grey-10);
  stroke: var(--color-grey-40);
}

.checkbox__wrapper .checkbox:checked:focus + svg .checkbox__bg,
.checkbox__wrapper .checkbox:focus + svg .checkbox__bg {
  stroke: var(--color-black);
  stroke-width: 2;
}

.checkbox__wrapper .checkbox:focus:not(:focus-visible) + svg .checkbox__bg {
  stroke: var(--color-grey-40);
  stroke-width: 1;
}

.checkbox__wrapper
  .checkbox:checked:focus:not(:focus-visible)
  + svg
  .checkbox__bg {
  stroke: var(--color-orange);
}

.checkbox__wrapper .checkbox:checked + svg .checkbox__bg {
  fill: var(--color-orange);
  stroke: var(--color-orange);
}

.checkbox__wrapper .checkbox:checked + svg .checkbox__checkmark {
  fill: var(--color-white);
  stroke: var(--color-white);
}
