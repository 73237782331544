.loading-indicator {
  animation: stepRotate ease-in-out infinite forwards;
  animation-duration: var(--loading-indicator-animation-speed);
}

.loading-indicator__rect {
  fill: var(--color-green);
  animation: stepRotate ease-in-out infinite forwards,
    swapColor linear infinite forwards;
  animation-duration: var(--loading-indicator-animation-speed),
    var(--loading-indicator-animation-speed);
  transform-box: fill-box;
  transform-origin: 50% 50%;
}

.loading-indicator__rect:nth-child(2) {
  animation-delay: 0s, calc(-1 * var(--loading-indicator-animation-speed) / 4);
}

.loading-indicator__rect:nth-child(3) {
  animation-delay: 0s, calc(-2 * var(--loading-indicator-animation-speed) / 4);
}

.loading-indicator__rect:nth-child(4) {
  animation-delay: 0s, calc(-3 * var(--loading-indicator-animation-speed) / 4);
}

@keyframes stepRotate {
  12.5% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(90deg);
  }
  37.5% {
    transform: rotate(135deg);
  }
  50% {
    transform: rotate(180deg);
  }
  62.5% {
    transform: rotate(225deg);
  }
  75% {
    transform: rotate(270deg);
  }
  87.5% {
    transform: rotate(315deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes swapColor {
  12.5% {
    fill: var(--color-green-dark);
  }
  25% {
    fill: var(--color-red);
  }
  37.5% {
    fill: var(--color-red-dark);
  }
  50% {
    fill: var(--color-blue-light);
  }
  62.5% {
    fill: var(--color-orange);
  }
  75% {
    fill: var(--color-purple);
  }
  87.5% {
    fill: var(--color-blue);
  }
  100% {
    fill: var(--color-green);
  }
}
