.box__section {
  padding: 1.5rem;
  background-color: var(--color-white);
}

.box__section:not(:last-child) {
  border-bottom: 2px solid var(--main-background-color);
}

.box__section:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.box__section:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.box__section--grey {
  background-color: #f7f7f7;
}
