.event-compliance {
  font-size: var(--font-size-xsmall);
}

.event-compliance--success {
  color: var(--color-green-darker);
}

.event-compliance__missed-events {
  color: var(--color-grey-70);
}
