.file-upload-progress {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  color: var(--color-grey-60);
}

.file-upload-progress__indicator {
  display: flex;
}

.file-upload-progress__indicator .circular-progress__background {
  stroke: var(--color-grey-20);
}

.file-upload-cancel {
  position: absolute;
  margin: 5px;
}

.file-upload-cancel .button__icon {
  height: unset !important;
  max-width: unset !important;
}
