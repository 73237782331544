.form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Monument Grotesk", Arial, Helvetica, sans-serif;
  padding: 1rem 1.25rem 1.875rem;
}

.form-item:lang(ja) {
  font-family: "MPlus Rounded";
}

.form-item:lang(el) {
  font-family: Arial, Helvetica, sans-serif;
}

.form-item--static {
  padding-bottom: 1.25rem;
}

.form-item__label {
  margin-bottom: 1rem;
}

@media (max-width: 30rem) {
  .form-item {
    padding: 0 0 0.625rem;
    align-items: stretch;
  }

  .form-item--static {
    padding-bottom: 0;
  }
}

@media print {
  .form-item {
    break-inside: avoid;
  }
}
