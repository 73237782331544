.staggered-animation-appear {
  opacity: 0;
}

.staggered-animation-appear-active {
  opacity: 1;
  transition: opacity 400ms;
}

.staggered-animation-enter {
  opacity: 0;
}

.staggered-animation-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.staggered-animation-exit {
  opacity: 1;
}

.staggered-animation-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.staggered-animation__item {
  animation: slideIn 650ms cubic-bezier(0.33, 1, 0.68, 1) forwards;
}

@keyframes slideIn {
  to {
    transform: translateY(0);
  }
}
