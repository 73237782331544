.date-icon {
  display: inline-flex;
  flex-direction: column;
  width: 3.875rem;
  height: 3.875rem;
  flex-shrink: 0;
}

.date-icon__date {
  background-color: var(--color-white);
  font-size: var(--font-size-large);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0.75rem 0.75rem;
  flex-grow: 1;
}

.date-icon__header {
  background-color: #e43e44;
  border-radius: 0.75rem 0.75rem 0 0;
  height: 25%;
}

.date-icon--inactive .date-icon__header {
  background-color: var(--color-grey-30);
}

.date-icon--inactive .date-icon__date {
  color: var(--color-grey-60);
}

.date-icon--small {
  width: 3.125rem;
  height: 3.125rem;
}

.date-icon--small .date-icon__date {
  border-radius: 0 0 0.625rem 0.625rem;
}

.date-icon--small .date-icon__header {
  border-radius: 0.625rem 0.625rem 0 0;
}
