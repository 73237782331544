.main-header {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding-right: var(--header-spacing-right);
  padding-left: var(--header-spacing-left);
  position: sticky;
  top: 0;
  background-color: inherit;
  z-index: 1;
}

.main-header__logo {
  margin-right: auto;
}

[dir="rtl"] .main-header__logo {
  margin-right: 0;
  margin-left: auto;
}

.main-header__logo-image {
  height: 1.375rem;
  width: auto;
  display: block;
}

.main-header__menu-button {
  border: none;
  background: none;
  cursor: pointer;
  color: var(--color-black);
  margin-left: 23px;
}

[dir="rtl"] .main-header__menu-button {
  margin-left: 0;
  margin-right: 23px;
}

.main-header__menu-button:hover {
  color: var(--color-grey-70);
}

.main-header__menu-button-icon {
  height: 2rem;
  width: 2rem;
}

@media (max-width: 48rem) {
  .main-header__help-button {
    display: none;
  }
}
