.thumbnail {
  position: relative;
}

.thumbnail svg {
  width: 100%;
  height: 100%;
}

.thumbnail__extension {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 6%;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

.thumbnail--link {
  cursor: pointer;
}
