.date-and-time {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  width: 100%;
}

.date-and-time__unknown-button {
  width: auto;
}

@media (max-width: 30rem) {
  .date-and-time {
    flex-direction: column;
    align-items: stretch;
  }

  .date-and-time__unknown-button {
    flex-direction: column;
  }
}
