.date-time-picker {
  position: relative;
  width: 16rem;
}

.date-time-picker__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* to make sure that the input type date still looks like a text input  */
  display: block;
  height: 2.688rem;
  width: 100%;
  padding: 0.625rem 2rem 0.625rem 1rem;
  border-radius: 2.5rem !important;
  font-size: var(--font-size-xsmall);
  font-family: "Monument Grotesk", Arial, Helvetica, sans-serif;
  background: var(--color-grey-10);
  border-color: transparent !important;
}

.date-time-picker__input:lang(el) {
  font-family: Arial, Helvetica, sans-serif;
}

.date-time-picker__input:hover {
  border-color: var(--color-grey-50);
}

.date-time-picker__input,
.date-time-picker__input::placeholder,
.date-time-picker__input::-webkit-date-and-time-value {
  text-align: left;
}

.date-time-picker__input:not(:disabled),
.date-time-picker__input:not(:disabled)::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

.date-time-picker--selected .date-time-picker__input {
  background: var(--color-orange) !important;
  color: var(--color-white) !important;
  -webkit-text-fill-color: var(--color-white); /* Safari */
  opacity: 1; /* iOS */
}

.date-time-picker--error .date-time-picker__input {
  border: 1px solid var(--color-red) !important;
}

.date-time-picker__icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  transition: transform 200ms;
  width: 0.938rem;
  pointer-events: none;
}

.date-time-picker--selected .date-time-picker__icon {
  color: var(--color-white);
}

@media (max-width: 30rem) {
  .date-time-picker {
    width: 100%;
  }
}

/* Fixes issue with placeholder text not spanning whole input on iOS v.15 */
.date-time-picker__input::before {
  display: inline-block;
  text-align: left;
  width: 100%;
}

/* Confirm/Reset plugin */
.date-time-picker__actions {
  padding: 10px 10px 0 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 2px solid var(--color-grey-10);
}

.date-time-picker__reset-button {
  color: var(--color-grey-70);
}

.date-time-picker__reset-button:hover {
  background: none;
  color: black;
}

/* flatpickr overrides */

.flatpickr-calendar {
  font-family: "Monument Grotesk", Arial, Helvetica, sans-serif;
  border-radius: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.flatpickr-calendar:lang(el) {
  font-family: Arial, Helvetica, sans-serif;
}

.flatpickr-calendar::before {
  display: none;
}

.flatpickr-calendar::after {
  display: none;
}

.flatpickr-rContainer {
  width: 100%;
}

.flatpickr-current-month {
  font-size: var(--font-size-small);
  font-weight: 400;
  left: 0px;
  width: 100%;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
}

.flatpickr-months .flatpickr-month {
  height: 46px;
}

.flatpickr-monthDropdown-months {
  background: url("../../../content/icons/arrow-small.svg") no-repeat right !important;
  font-weight: normal !important;
  -webkit-appearance: none !important;
  padding-right: 18px !important;
  line-height: 1.1 !important;
  min-width: 0;
  flex: 0 1 auto;
  text-overflow: ellipsis;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: none;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  display: none;
}

.flatpickr-weekdays {
  padding: 0 10px;
}

span.flatpickr-weekday {
  color: var(--color-grey-70);
  font-weight: normal;
}

.flatpickr-day {
  color: var(--color-black);
}

.dayContainer {
  padding: 0 10px;
}

.flatpickr-day.selected,
.flatpickr-day.selected:hover {
  background-color: var(--color-green-dark) !important;
  border-color: var(--color-green-dark);
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  background-color: var(--color-grey-70);
  border-color: var(--color-grey-70);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: var(--color-grey-60);
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 2px solid var(--color-grey-10) !important;
  padding: 10px;
  max-height: 61px;
  height: 61px;
  align-items: center;
}

.flatpickr-calendar.noCalendar .flatpickr-time {
  border-top: none !important;
  padding-top: 5px;
}

.flatpickr-time-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px !important;
  font-size: var(--font-size-fluid-xxsmall);
}

.numInputWrapper input {
  font-family: "Monument Grotesk", Arial, Helvetica, sans-serif !important;
  font-size: var(--font-size-xsmall) !important;
  font-weight: normal !important;
  color: var(--color-black) !important;
  border-radius: 10px !important;
  border: 2px solid #d1d1d1 !important;
  text-align: left;
  padding: 5px 5px 5px 9px !important;
}

.numInputWrapper input:lang(el) {
  font-family: Arial, Helvetica, sans-serif !important;
}

.flatpickr-current-month .numInputWrapper {
  flex: 0 0 auto;
  width: 137px;
  position: relative;
}

.flatpickr-current-month input.cur-year {
  height: 41px;
}

.flatpickr-time input:hover,
.numInputWrapper:hover {
  background: none !important;
}

.flatpickr-time input:focus {
  background: none;
}

.flatpickr-time .numInputWrapper {
  height: 41px;
}

.numInputWrapper span.arrowUp,
.numInputWrapper span.arrowDown {
  opacity: 1;
  border: none;
}

.numInputWrapper span.arrowUp,
.numInputWrapper span.arrowDown {
  opacity: 1;
  height: 14px;
  width: 31px;
  right: 5px;
  background: var(--color-grey-10);
}

.numInputWrapper span.arrowUp:after,
.numInputWrapper span.arrowDown:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.numInputWrapper span.arrowUp {
  top: 5px;
  border-radius: 5px 5px 0px 0px;
}

.numInputWrapper span.arrowDown {
  top: unset;
  bottom: 5px;
  border-radius: 0px 0px 5px 5px;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: 400;
}

/* Fixes issue with placeholder not being visible on Android Chrome https://github.com/flatpickr/flatpickr/issues/1094 */
.flatpickr-mobile:before {
  content: attr(placeholder);
  color: var(--color-black);
}

.flatpickr-mobile:focus[value]:not([value=""]):before {
  display: none;
}

input[type="hidden"][value]:not([value=""]) + .flatpickr-mobile:before {
  display: none;
}
