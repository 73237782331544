.video-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}

.video-controls--minimized {
  justify-content: space-around;
  margin: 0;
}

@media (max-width: 48rem) {
  .video-controls {
    justify-content: space-around;
  }
}
