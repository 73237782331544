.next-event {
  color: var(--color-grey-70);
}

.next-event--active {
  background-color: var(--color-green-dark);
  color: var(--color-white);
}

.next-event__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.next-event__title {
  font-size: var(--font-size-xxsmall);
  line-height: 0.8;
  color: var(--color-black);
  margin-bottom: 0.375rem;
}

.next-event--active .next-event__title {
  color: var(--color-white);
}

.next-event__heading {
  display: flex;
  flex-direction: column;
}
