.main-menu-drawer {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.main-menu-drawer__overlay {
  opacity: 0;
  transition: opacity 500ms;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-menu-drawer__content {
  transform: translateX(100%);
  transition: transform 500ms;
  width: 100%;
  background: var(--color-black);
  color: var(--color-white);
  margin-left: auto;
  height: 100%;
  padding: 2.25rem 1.5rem 3rem;
  padding: 2.25rem clamp(1.5rem, 3.33vw, 3rem) 3rem;
  display: flex;
  flex-direction: column;
}

[dir="rtl"] .main-menu-drawer__content {
  transform: translateX(-100%);
  margin-left: 0;
  margin-right: auto;
}

.main-menu-drawer__content:focus-visible {
  outline: none;
}

@media (min-width: 48rem) {
  .main-menu-drawer__content {
    width: 488px;
  }
}

.main-menu-drawer--open {
  pointer-events: auto;
}

.main-menu-drawer--open .main-menu-drawer__overlay {
  opacity: 1;
}

.main-menu-drawer--open .main-menu-drawer__content {
  transform: translateX(0);
}

.main-menu-drawer--hidden {
  visibility: hidden;
}

.main-menu-drawer__scroll-content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.main-menu-drawer__scroll-content::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.main-menu-drawer__close-button {
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  line-height: 0;
  margin-bottom: 2.25rem;
  align-self: flex-start;
  transition: color 200ms;
}

.main-menu-drawer__close-icon {
  height: 2rem;
  width: 2rem;
}

.main-menu-drawer__link-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 5rem;
}

.main-menu-drawer__link-list-item:not(:last-child) {
  margin-bottom: 1rem;
}

.main-menu-drawer__link {
  text-decoration: none;
}

.main-menu-drawer__link-list-item--mobile-only {
  display: none;
}

@media (max-width: 48rem) {
  .main-menu-drawer__link-list-item--mobile-only {
    display: list-item;
  }
}

.main-menu-drawer__button-link {
  font-family: inherit;
  background: none;
  border: none;
  padding: 0;
}

.main-menu-drawer__link,
.main-menu-drawer__button-link {
  color: var(--color-white);
  font-size: var(--font-size-xlarge);
  cursor: pointer;
}

.main-menu-drawer__link:hover,
.main-menu-drawer__button-link:hover,
.main-menu-drawer__close-button:hover {
  color: var(--color-grey-40);
}
