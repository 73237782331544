:root {
  --vas-scale-length: 644px; /* Vas Scale has to be 10cm on iPad mini */
  --vas-scale-label-offset: 1.5rem;
  --vas-scale-track-width: 30px;
  --vas-scale-label-tick-height: 10px;
  --vas-scale-gradient: #5eb158 10%, #81bd54 10% 20%, #9cc753 20% 30%,
    #b0ce51 30% 40%, #cad850 40% 50%, #dbd54e 50% 60%, #e0c149 60% 70%,
    #de9a43 70% 80%, #dd713b 80% 90%, #da3f33 90% 100%;
  --vas-scale-tick-width: 2px;
}

.vas-scale {
  position: relative;
  max-width: var(--vas-scale-length);
  width: 100%;
  font-size: var(--font-size-xsmall);
}

.vas-scale__track {
  display: flex;
  height: var(--vas-scale-track-width);
  background: var(--color-blue);
  width: 100%;
  cursor: pointer;
  position: relative;
  touch-action: none;
  flex-shrink: 0;
  z-index: 1;
}

.vas-scale__track:focus {
  outline: none;
}

.vas-scale__track:focus-visible {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.vas-scale--full-color .vas-scale__track {
  background: linear-gradient(to right, var(--vas-scale-gradient));
}

.vas-scale__label-ticks {
  height: var(--vas-scale-label-tick-height);
  border-right: var(--vas-scale-tick-width) solid var(--color-grey-40);
  border-left: var(--vas-scale-tick-width) solid var(--color-grey-40);
}

.vas-scale__label-wrapper {
  flex: 1 1 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.vas-scale__label {
  position: relative;
  word-wrap: break-word;
}

.vas-scale__label:first-of-type {
  left: calc(-1 * var(--vas-scale-label-offset));
}

.vas-scale__label:last-of-type {
  text-align: right;
  right: calc(-1 * var(--vas-scale-label-offset));
}

.vas-scale__top-label {
  margin-bottom: 2rem;
}

.vas-scale__bottom-label {
  margin-top: 2rem;
}

.vas-scale__controls {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  gap: 13px;
}

.vas-scale__numeric-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 4.375rem;
  height: 2rem;
  padding: 0 1.25rem;
  border: 1px solid #d1d1d1;
  background: var(--color-white);
  border-radius: 0.624rem;
}

.vas-scale--error .vas-scale__numeric-feedback {
  border: 1px solid var(--color-red);
}

.vas-scale__numeric-feedback-label {
  margin-bottom: 1rem;
  word-spacing: 9999999px; /* one word per row */
  text-align: center;
}

.vas-scale__reset-button {
  display: flex;
  background: none;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  color: var(--color-black);
  cursor: pointer;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.vas-scale__reset-button:hover {
  color: var(--color-grey-70);
}

.vas-scale__reset-icon {
  height: 1.125rem;
  transform: rotate(-45deg) translateY(-2px);
}

.vas-scale--read-only {
  pointer-events: none;
}

.vas-scale--read-only .vas-scale__numeric-feedback {
  background: var(--color-grey-10);
  color: var(--color-grey-70);
}

/* VERTICAL */

.vas-scale--vertical {
  height: var(--vas-scale-length);
  padding: 0;
  display: flex;
}

.vas-scale--vertical .vas-scale__section {
  display: flex;
  flex: 1 1 0;
  min-width: 0;
  position: relative;
}

.vas-scale--vertical .vas-scale__track {
  height: 100%;
  width: var(--vas-scale-track-width);
}

.vas-scale--vertical.vas-scale--full-color .vas-scale__track {
  background: linear-gradient(to top, var(--vas-scale-gradient));
}

.vas-scale--vertical .vas-scale__label-wrapper {
  position: absolute;
  flex-direction: column-reverse;
  padding-bottom: 0;
  padding-right: 1.5rem;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.vas-scale--vertical .vas-scale__label {
  text-align: right;
  left: unset;
}

.vas-scale--vertical .vas-scale__label-ticks {
  height: 100%;
  border-right: none;
  border-left: none;
  border-top: var(--vas-scale-tick-width) solid var(--color-grey-40);
  border-bottom: var(--vas-scale-tick-width) solid var(--color-grey-40);
  width: var(--vas-scale-label-tick-height);
}

.vas-scale--vertical .vas-scale__label:first-of-type {
  left: unset;
  transform: translateY(0.5em);
}

.vas-scale--vertical .vas-scale__label:last-of-type {
  right: unset;
  transform: translateY(-0.65em);
}

.vas-scale--vertical .vas-scale__controls {
  flex: 1 1 0;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
}

@media (max-width: 30rem) {
  :root {
    --vas-scale-length: 400px;
    --vas-scale-label-offset: 0.5rem;
  }
}

/* RTL */

[dir="rtl"] .vas-scale--full-color:not(.vas-scale--vertical) .vas-scale__track {
  background: linear-gradient(to left, var(--vas-scale-gradient));
}

[dir="rtl"] .vas-scale__label:first-of-type {
  text-align: right;
  right: calc(-1 * var(--vas-scale-label-offset));
}

[dir="rtl"] .vas-scale__label:last-of-type {
  text-align: left;
  right: unset;
  left: calc(-1 * var(--vas-scale-label-offset));
}

[dir="rtl"] .vas-scale--vertical .vas-scale__label-wrapper {
  padding-left: 1.5rem;
}

[dir="rtl"] .vas-scale--vertical .vas-scale__label:first-of-type {
  text-align: left;
  right: unset;
}

[dir="rtl"] .vas-scale--vertical .vas-scale__label:last-of-type {
  left: unset;
}
